
import base64toBlob from "@/utils/rv/base64toBlob";
import { defineComponent, ref, reactive } from "vue";
import {
  UploadOutlined,
  DeleteOutlined,
  CloseOutlined,
  PaperClipOutlined,
} from "@ant-design/icons-vue";
import {
  getLicenseInfo,
  getLicensePicture,
  saveLicenseInfo,
  uploadLicenseFile,
  sumbitLicenseInfo,
  uploadAppealItem,
  deleteAppealItem,
} from "@/API/vl/vlSICCOChecking";
import { FileItem } from "@/API/types";
import NoticeModal from "@/components/NoticeModal.vue";
import { message } from "ant-design-vue";
import { omit } from "lodash-es";
import moment from "moment";

const invalidNameChar = /'|’|"|“|\/|;|；|--|\\|=|,|，|<|《 |>|》|{|}|\[|【|]|】|!|！|#|\$|%|&|\*|\+|\.|。|:|：|\?|？|@|~/

export default defineComponent({
  components: {
    UploadOutlined,
    DeleteOutlined,
    CloseOutlined,
    NoticeModal,
    PaperClipOutlined,
  },
  exits: ["close-appeal-page-modal"],
  setup(_, { emit }) {
    const data = reactive({
      appealComment: "",
      customerName: "",
      engineNo: "",
      issueDate: "",
      licenseNumber: "",
      regDate: "",
      useNatue: "",
      vehicleType: "",
      vinNo: "",
      dealerCode: sessionStorage.getItem("appealDealerCode") || "",
      licensePlates: [],
      uploadDate: "",
      retailDate: "",
      licenseDate: ""
    });
    const appealComment = ref("");
    const deleteClick = ref(false);
    //弹窗显示
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    //行驶证图片
    const pictureModal = reactive({
      pictureModalImg: "",
      pictureModalTitle: "",
    });

    //上传行驶证
    const modalVisible = ref(false);
    const modalData = reactive({});
    const ssFileList = ref<FileItem[]>([]);
    const tempLicenseData = ref();
    const tempAppealData = ref();

    //规则校验
    const formRef = ref();
    const rules = {
      licenseNumber: {
        required: true,
        validator: async (rule: any, value: string) => {
          if (/^[\u4e00-\u9fa5a-zA-Z0-9]+$/.test(value)) {
            if (data.licensePlates.map((item: string) => item.substring(0, Math.min(value.length, 2)))
              .includes(value.substring(0, 2))) {
              return Promise.resolve();
            } else {
              return Promise.reject('车牌号无效，请核对');
            }
          } else {
            if (value == '') {
              return Promise.reject('车牌号码为必填项');
            } else {
              return Promise.reject('请输入数字、汉字或字母');
            }
          }
        },
        trigger: 'change'
      },
      customerName:         {
        required: true,
        validator: async (rule: any, value: string) => {
          if (value === '') {
            return Promise.reject('客户名称为必填项');
          } else if (invalidNameChar.test(value)) {
            return Promise.reject('客户名称包含特殊字符，请核对');
          } else {
            return Promise.resolve();
          }
        },
        trigger: 'change'
      },
      engineNo: {
        required: true,
        validator: async (rule: any, value: string) => {
          if (/^[a-zA-Z0-9]+$/.test(value)) {
            return Promise.resolve();
          } else {
            if (value == '') {
              return Promise.reject('发动机号码为必填项');
            } else {
              return Promise.reject('请输入字母或数字');
            }
          }
        },
        trigger: 'change'
      },
      useNatue: {
        required: true,
        validator: async (rule: any, value: string) => {
          if (/^[\u4e00-\u9fa5]+$/.test(value)) {
            return Promise.resolve();
          } else {
            if (value == '') {
              return Promise.reject('使用性质为必填项');
            } else {
              return Promise.reject('请输入汉字');
            }
          }
        },
        trigger: 'change'
      },
      regDate: {
        required: true,
        validator: async (rule: any, value: string) => {
          if (!value) {
            return Promise.reject('注册日期为必填项');
          }
          const regDate = moment(value);
          if (data.issueDate && regDate.isAfter(data.issueDate, 'day')) {
            return Promise.reject('发证日期无效，请核对');
          }
          if (regDate.isAfter(data.uploadDate, 'day')) {
            return Promise.reject('发证日期无效，请核对');
          }
          const usage = sessionStorage.getItem('appealUsage');
          if (usage === '最终用户用车') {
            if (data.retailDate && regDate.isBefore(data.retailDate, 'day')) {
              return Promise.reject('发证日期无效，请核对');
            }
          } else {
            if (data.licenseDate && regDate.month() !== moment(data.licenseDate).month()) {
              return Promise.reject('发证日期无效，请核对');
            }
          }
          return Promise.resolve();
        },
        trigger: 'change'
      },
      vehicleType: {
          required: true,
          validator: async (rule: any, value: string) => {
            if (/^[\u4e00-\u9fa5]+$/.test(value)) {
              return Promise.resolve();
            } else {
              if (value == '') {
                return Promise.reject('车辆类型为必填项');
              } else {
                return Promise.reject('请输入汉字');
              }
            }
          },
          trigger: 'change'
      },
      issueDate: {
        required: true,
        validator: async (rule: any, value: string) => {
          if (!value) {
            return Promise.reject('发证日期为必填项');
          }
          const issueDate = moment(value);
          if (issueDate.isAfter(data.uploadDate, 'day')) {
            return Promise.reject('发证日期无效，请核对');
          }
          if (data.regDate && issueDate.isBefore(data.regDate, 'day')) {
            return Promise.reject('发证日期无效，请核对');
          }
          return Promise.resolve();
        },
        trigger: 'change'
      }
    };

    const confirmModalVisible = ref(false);
    const checkResult = ref('');

    const computeSize = (fileSize: string) => {
      const sizeNum = parseFloat(fileSize) / 1024 / 1024;
      const sizeKb = parseFloat(fileSize) / 1024;
      if (sizeNum < 1) {
        return sizeKb.toFixed(2) + "KB";
      } else {
        return sizeNum.toFixed(2) + "MB";
      }
    };

    const computeName = (fileName: string) => {
      if (fileName.length < 15) {
        return fileName;
      } else {
        return fileName.slice(0, 15).concat(".....");
      }
    };

    const getLicenseImg = async () => {
      deleteClick.value = false;
      const params = {
        vinNo: sessionStorage.getItem("appealvinNo"),
        dealerCode: sessionStorage.getItem("appealDealerCode"),
        fileName: "RE_" + sessionStorage.getItem("appealvinNo"),
      };
      return getLicensePicture(params).then((res: any): void => {
        if (!res.downloadAppealDetail) {
          pictureModal.pictureModalImg = res.downloadDetail.downloadUrl;
          pictureModal.pictureModalTitle = "RE_" + params.vinNo + ".jpg";
          data["licensePath"] = res.licensePath;
        } else {
          pictureModal.pictureModalImg = res.downloadAppealDetail.downloadUrl;
          pictureModal.pictureModalTitle = "RE_" + params.vinNo + ".jpg";
          data["licensePath"] = res.appealLicensePath;
        }
      });
    };

    //获取行驶证信息
    const getLicenseItem = () => {
      const vinNo: any = sessionStorage.getItem("appealvinNo");
      const dealerCode: string =
        sessionStorage.getItem("appealDealerCode") || "";
      getLicenseInfo(vinNo, dealerCode).then((res: any) => {
        Object.assign(data, res.data);
        ssFileList.value = res.data.appealFileInfoList.map((item: any) => {
          return {
            name: item.fileName,
            size: item.fileSize,
            uid: item.id,
            fileName: item.fileName,
          };
        });
      });
    };

    const deleteLicenseImg = () => {
      deleteClick.value = true;
      pictureModal.pictureModalTitle = "";
      pictureModal.pictureModalImg = "";
    };

    const closeModal = (params: { isVisible: boolean; continue: boolean }) => {
      modalVisible.value = params.isVisible;
    };

    //关闭页面
    const closePage = () => {
      emit("close-appeal-page-modal");
    };

    //申诉提交
    const sumbitLicenseDetail = async () => {
      await submitLicenseFile();
      delete data["appealFileInfoList"];
      await new Promise((resolve, reject) => {
        formRef.value.validate().then(() => {
          resolve(null)
        }).catch((e: any) => {
          for (const errorField of e.errorFields) {
            for (const error of errorField.errors) {
              if (error.includes('为必填项')) {
                reject(e)
                return
              }
            }
          }
          resolve(null)
        });
      });
      await sumbitLicenseInfo(omit(data, ['licensePlates', 'retailDate', 'uploadDate', 'licenseDate']));
      getLicenseImg();
      confirmModalVisible.value = false
      emit("close-appeal-page-modal");
    };

    const beforeUserUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "请上传小于5MB的文件";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const licenseNum = fileList.length < 10;
        if (!licenseNum) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "上传文件不能超过10张";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const isJpg = file.type === "image/jpeg";
        const isDocx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
        // const isDocx = file.type === 'application/msword';
        const isXlsx =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const isPdf = file.type === "application/pdf";
        if (!isJpg && !isDocx && !isXlsx && !isPdf) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content =
            "文件格式错误，请按照JPG DOCX XLSX PDF格式进行上传";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        tempAppealData.value = fileList;
        fileList.forEach((item: any) => (item.status = ""));
        // ssFileList.value.push(file);
        return resolve(file);
      });
    };

    const beforeUpload = (file: FileItem, fileList: any) => {
      return new Promise((resolve, reject) => {
        //判断上传文件是否大于5MB
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "请上传小于5MB的文件";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const licenseNum = fileList.length === 1;
        if (!licenseNum) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "上传文件不能超过100张";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        const isJpg = file.type === "image/jpeg";
        const isPdf = file.type === "application/pdf";
        if (!isJpg && !isPdf) {
          modalVisible.value = true;
          modalParams.noticeType = "error";
          modalParams.content = "上传文件格式为jpg,pdf";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          return false;
        }
        tempLicenseData.value = fileList;
        return resolve(true);
      });
    };

    //submitLicenseFile
    const submitLicenseFile = async () => {
      if (!tempLicenseData.value) {
        return
      }
      const formData = new FormData();
      tempLicenseData.value.forEach((item: any) => {
        formData.append("file", item);
      });
      const getvinNo: any = data.vinNo;
      const dealerCode: string =
        sessionStorage.getItem("appealDealerCode") || "";
      const res = await uploadLicenseFile(formData, getvinNo, dealerCode);
      if (!["0", "80000"].includes(res.code)) {
        message.error(res.message);
      }
    };
    const handleToSave = async () => {
      await new Promise((resolve, reject) => {
        formRef.value.validate().then(() => {
          resolve(null)
        }).catch((e: any) => {
          for (const errorField of e.errorFields) {
            for (const error of errorField.errors) {
              if (error.includes('为必填项')) {
                reject(e)
                return
              }
            }
          }
          resolve(null)
        });
      })
      checkResult.value = sessionStorage.getItem('appealCheckResult') ?? '';
      confirmModalVisible.value = true
    }
    //保存
    const saveLicenseDetail = async () => {
      submitLicenseFile();
      delete data["appealFileInfoList"];
      await saveLicenseInfo(omit(data, ['licensePlates', 'retailDate', 'uploadDate', 'licenseDate']))
      confirmModalVisible.value = false
    };
    const getBase64 = (img: any, callback: any) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => callback(reader.result));
      reader.readAsDataURL(img);
    };
    const getLicenseFile = (info: any) => {
        getBase64(info.file.originFileObj, (base64Url: any) => {
          pictureModal.pictureModalImg = base64Url;
    
        });

      
    };
    //submitAppealFile
    const submitAppealFile = (file: any) => {
      const appealData = new FormData();
      appealData.append("files", file.file.originFileObj);
      const getvinNo: any = data.vinNo;
      uploadAppealItem(appealData, getvinNo, data.dealerCode).then(
        (res: any) => {
          if (res.code == "0") {
            res.data.forEach((item: any) => {
              const newFile: any = {
                uid: item.id,
                name: item.fileName,
                fileName: item.fileName,
                size: item.fileSize,
                status: "done",
                downloadUrl: item.downloadUrl,
                base64String: item.base64String,
              };
              const namex = ssFileList.value.findIndex((item) => {
                return item.name === newFile.name;
              });
              if (namex === -1) {
                ssFileList.value.push(newFile);
              } else {
                ssFileList.value.splice(namex, 1, newFile);
              }
            });
          } else {
            message.error(res.message);
          }
        }
      );
    };
    //下载文件
    const downloadFile = (item: any) => {
      let URL = {};
      const fileName = item.fileName;
      const fileUrl = item.base64String;
      if (fileName.indexOf(".docx") > -1) {
        URL = base64toBlob(
          fileUrl,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      } else if (fileName.indexOf(".xlsx") > -1) {
        URL = base64toBlob(
          fileUrl,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
      } else if (fileName.indexOf(".jpg") > -1) {
        URL = base64toBlob(fileUrl, "image/jpeg");
      } else if (fileName.indexOf(".pdf") > -1) {
        URL = base64toBlob(fileUrl, "application/pdf");
      }
      const downloadElement = document.createElement("a");
      downloadElement.href = window.URL.createObjectURL(URL);
      downloadElement.download = fileName;
      downloadElement.click();
    };

    //删除文件
    const deleteAppealLink = (index: number) => {
      deleteAppealItem(ssFileList.value[index].uid).then((res: any) => {
        if (res.code == "0") {
          ssFileList.value.splice(index, 1);
        } else {
          message.error(res.message);
        }
      });
    };
    //初始化
    const init = () => {
      getLicenseImg();
      getLicenseItem();
    };
    init();

    return {
      ssFileList,
      data,
      appealComment,
      rules,
      pictureModal,
      modalParams,
      formRef,
      confirmModalVisible,
      checkResult,
      deleteLicenseImg,
      deleteClick,
      beforeUserUpload,
      beforeUpload,
      closePage,
      modalVisible,
      modalData,
      closeModal,
      handleToSave,
      saveLicenseDetail,
      submitLicenseFile,
      sumbitLicenseDetail,
      submitAppealFile,
      computeSize,
      deleteAppealLink,
      computeName,
      downloadFile,
      getLicenseFile,
    };
  },
  BeforeUnmount() {
    sessionStorage.clear();
  },
});
